import { BaseLoginProvider, SocialUser } from '@abacritt/angularx-social-login';

export class DiscordLoginProvider extends BaseLoginProvider {
  public static readonly PROVIDER_ID: string = 'DISCORD';

  protected auth2: any;

  constructor(
    private clientId: string,
    private initOptions: any = {
      scope: 'identify email',
      responseType: 'code',
    },
    private redirectUri?: string
  ) {
    super();
    this.loggedIn = false;
  }

  private user: SocialUser;
  private loggedIn: boolean;
  private url: string;

  getRedirectUrl() {
    if (this.redirectUri) {
      return this.redirectUri;
    }
    return window?.location?.href.split('?')[0];
  }

  getLoginStatus(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      if (this.loggedIn) {
        resolve(this.user);
      } else {
        reject('No user is currently logged in.');
      }
    });
  }

  initialize(): Promise<void> {
    return new Promise((resolve) => {
      resolve();
    });
  }

  signIn(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      this.url = `https://discord.com/api/oauth2/authorize?client_id=${
        this.clientId
      }&redirect_uri=${this.getRedirectUrl()}&response_type=${
        this.initOptions.responseType
      }&scope=${this.initOptions.scope}`;

      window.open(this.url, '_top');
    });
  }

  signOut(revoke?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loggedIn = false;
      resolve();
    });
  }
}
